@use "sass:meta"
@import "ag-grid-community/src/styles/ag-grid.scss"
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin"

$font-family-sans-serif: 'Nunito', 'Source Sans Pro', 'sans-serif!important'

.ag-theme-alpine
  --ag-background-color: rgb(255 255 255)
  --ag-odd-row-background-color: rgb(255 255 255)
  --ag-foreground-color: rgba(6, 25, 56, 1)
  --ag-row-hover-color: rgba(251, 229, 236, 1)

  .ag-root-wrapper
    border-width: 0 !important
    font-family: #{meta.inspect($font-family-sans-serif)}

  .ag-header
    border-bottom: 0 !important
    border-style: none
    overflow: visible
    @apply bg-light-40

  .ag-header-row, .ag-header-viewport, .ag-header-cell
    border-width: 0 !important
    font-weight: 600
    overflow: visible
    @apply bg-light-40 rounded-t-xl text-dark-500 text-base
    
  .ag-row
    border-top: 0px
    overflow: visible
    @apply text-dark-900

  .ag-cell  
    line-height: normal
    overflow: visible

  .cell-span 
    background-color: white

  .thick-top-border
    border-top: 2px solid #E23668 !important
  
  .thick-bottom-border
    border-bottom: 2px solid #E23668 !important
    
  
.ag-layout-auto-height .ag-center-cols-clipper,
.ag-layout-auto-height .ag-center-cols-container,
.ag-layout-print .ag-center-cols-clipper,
.ag-layout-print .ag-center-cols-container
  min-height: 0px !important

// HTML EDITOR QUILL
.ql-toolbar
  @apply border-gray-200 border-t-0 border-r-0 border-l-0 #{!important}
  @apply border-b-2 #{!important}
.ql-container
  @apply border-none #{!important}

.editor-lists 
  ol,ul
    padding-left: theme('spacing.10')

  ol
    list-style-type: decimal

  ul
    list-style-type: disc
