html,
body
  @apply bg-light-20 font-body

// For Legacy Pages
nav.legacy
  box-shadow: 0.0625rem 0.5rem 0.5rem #e5e5e5

pre
  white-space: pre-wrap
  white-space: -moz-pre-wrap
  white-space: -pre-wrap
  white-space: -o-pre-wrap
  word-wrap: break-word
  font: inherit

// /blogs
.blog-content
  p
    @apply py-4
  a
    @apply text-bluegray-300
  h2
    @apply text-xl font-bold py-3
  h3
    @apply text-xl font-bold py-2
  blockquote
    @apply py-3 px-6 my-4 bg-light-30

input:focus
  &.focus-no-shadow
    box-shadow: none

button:focus
  &.focus-no-shadow
    box-shadow: none
    outline: none

.primary-button
  @apply p-2.5 px-4 font-semibold flex items-center justify-center my-2 rounded-full outline-none

.page-heading
  @apply text-2xl sm:text-4xl sm:font-bold

.application-input
  @apply flex flex-col space-y-1 my-4
  &.required
    label:after
      content: '*'
      @apply text-red-400
  label
    @apply text-dark-600 md:text-dark-900 font-semibold
  input
    @apply border border-light-40 rounded-full px-4 h-9 text-mid-300 w-full bg-white focus:border-inherit focus:ring-0
    &:disabled
      @apply bg-light-20 opacity-50
  textarea
    @apply border border-light-40 rounded-lg px-4 text-mid-300 w-full
  button
    @apply bg-bluegray-300 text-white rounded-full px-4 h-9
    &:disabled
      @apply opacity-50

@keyframes slide-up
  0% 
    transform: translateY(100%)
  100%
    transform: translateY(0)

@keyframes slide-down
  0% 
    transform: translateY(0)
  100%
    transform: translateY(100%)

.slide-up
  animation: slide-up cubic-bezier(0.4, 0, 0.2, 1) 500ms

.slide-down
  animation: slide-down 0.3s 10ms forwards

@keyframes slide-left
  0% 
    transform: translateX(100%)
  100%
    transform: translateX(0)

@keyframes slide-right
  0% 
    transform: translateX(0)
  100%
    transform: translateX(100%)

.slide-left
  animation: slide-left cubic-bezier(0.4, 0, 0.2, 1) 300ms

.slide-right
  animation: slide-right 0.3s 10ms forwards

.ql-editor
  resize: vertical
  min-height: 9rem
