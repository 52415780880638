@tailwind base

@import 'custom.sass'

@import 'theme.sass'

@tailwind components

@tailwind utilities

@layer base
  .screen-padding
    padding-right: calc((100vw - 1280px)/2 + 64px)
    padding-left: calc((100vw - 1280px)/2 + 64px)